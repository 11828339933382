import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['dateInput']

  initialize () {}

  handleDateSelected (e) {
    let value = e.currentTarget.value
    if (value) {
      document.querySelector('#arrival-date-form').submit()
    }
  }
}
