// src/controllers/clipboard_controller.js
import { Controller } from 'stimulus'
import Choices from "choices.js"

export default class extends Controller {
  static targets = ['type', 'number', 'checkin', 'checkout']

  connect () {
    this.getState()
    this.updateState()
    this.handleDisable()
    this.runOnceGetRoomNumbers()
  }

  // returns you the current state of the form according to the DOM
  getState () {
    return {
      checkin: this.checkinTarget.value,
      checkout: this.checkoutTarget.value,
      type: this.typeTarget.value,
      number: Array.from(this.numberTarget.selectedOptions).map(opt=> opt.value)
    }
  }

  // updates the state of this controller instance
  updateState () {
    this.state = this.getState()
    this.disableAll()
    this.handleDisable()
  }

  handleDisable () {
    if (this.state.checkin && this.state.checkout) {
      this.typeTarget.disabled = false
    }
    if (this.state.checkin && this.state.checkout && this.state.type) {
      this.numberTarget.disabled = false
    }
  }

  disableAll () {
    this.checkinTarget.disabled = false
    this.checkoutTarget.disabled = false
    this.typeTarget.disabled = true
    this.numberTarget.disabled = true
  }

  getRoomNumbers () {
    this.updateState()
    if (this.roomNumberChoicesInstance) {
      this.roomNumberChoicesInstance.destroy()
    }
    let { checkin, checkout, type, number } = this.state
    if (checkin && checkout && type) {
      const url = `/api/v1/room_types/${type}/room_numbers?checkin_date=${checkin}&checkout_date=${checkout}`
      fetch(url)
        .then(response => {
          return response.json()
        })
        .then(data => {
          this.numberTarget.innerHTML = ''
          data.forEach(room => {
            let { id, room_number, available, status } = room
            let opt = document.createElement('option')
            opt.value = id
            opt.innerHTML = room_number
            let roomOwner = number.includes(id.toString())
            if (!available && !roomOwner) {
              opt.style = 'background: #8f8f8f; color: white;'
              opt.disabled = true
              if (status == 'maintenance') {
                opt.innerHTML = `${room_number} (Maintenance)`
              }
            } else if (roomOwner){
              opt.selected = true
            }
            this.numberTarget.append(opt)
          })

          this.roomNumberChoicesInstance = new Choices(this.numberTarget, {
            itemSelectText: "Add room number",
            removeItems: true,
            addItems: true,
            removeItemButton: true,
            placeholder: true,
            placeholderValue: 'Test'
          })
        })
    }
  }

  runOnceGetRoomNumbers () {
    this.updateState()
    
    if (!window.ranFilter) {
      this.getRoomNumbers()
      window.ranFilter = true
    }
  }
}
