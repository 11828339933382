require('@rails/ujs').start()
require('turbolinks').start()

import '../stylesheets/application.scss'
import 'bootstrap'
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
const application = Application.start()
const context = require.context('./../controllers', true, /\.js$/)
application.load(definitionsFromContext(context))

import $ from 'jquery'
global.$ = jQuery
document.addEventListener('turbolinks:load', () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('#booking_room_type').change()
})


// $(document).on("change", "#booking_room_type", function(){
//   var room_type = $(this).val();

//   $.ajax({
//     url: "/room_types",
//     method: "GET",
//     dataType: "json",
//     data: {room_type: room_type},
//     error: function (xhr, status, error) {
//       console.error('AJAX Error: ' + status + error);
//     },
//     success: function (response) {
//       var room_numbers = response["room_numbers"];
//       console.log(room_numbers)
//       $("#booking_room_number").empty();

//       for(var i=0; i< room_numbers.length; i++){
//         $("#booking_room_number").append('<option value="' + room_numbers[i]["id"] + '">' + room_numbers[i]["number"] + '</option>');
//       }
//     }
//   });
// });

// // check how to only run on bookings page.
// document.addEventListener("turbolinks:load", function() {
//   var calendarEl = document.getElementById('calendar');

//   $.ajax({
//     url: "/room_numbers",
//     method: "GET",
//     dataType: "json",
//     error: function (xhr, status, error) {
//       console.error('AJAX Error: ' + status + error);
//     },
//     success: function (response) {
//       var events    = []
//       var resources = []

//       $.map(response, function( r ) {
//         resources.push({
//           id: r.id,
//           room_type: r.room_type,
//           title: r.room_number
//         })

//         if (r.bookings && r.bookings.length) {
//             $.map(r.bookings, function(booking) {
//               events.push({
//                 id: booking.id,
//                 title: booking.title,
//                 resourceIds: [r.id],
//                 start: booking.checkin_date,
//                 end: booking.checkout_date,
//                 show_url: booking.show_url,
//                 description: booking.description,
//                 display: 'background',
//                 color: '#ff9f89'
//               });
//             })
//         }
//       });

//       var calendar = new FullCalendar.Calendar(calendarEl, {
//         timeZone: 'UTC',
//         initialView: 'resourceTimelineMonth',
//         aspectRatio: 1.5,
//         headerToolbar: {
//           left: 'prev,next',
//           center: 'title',
//           right: 'resourceTimelineMonth'
//         },
//         editable: true,
//         resourceAreaHeaderContent: 'Rooms',
//         resourceGroupField: 'room_type',
//         resources: resources,
//         events: events,
//         eventClick: function (info) {
//           info.jsEvent.preventDefault()
//           Turbolinks.visit(info.event.extendedProps.show_url)
//         },
//         eventDidMount: function(info) {
//           $(info.el).tooltip({
//             title: info.event.extendedProps.description,
//             placement: "top",
//             trigger: "hover",
//             container: "body"
//           });
//         }
//       })
//       calendar.render();
//     }
//   });
// });
