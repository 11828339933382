// src/controllers/clipboard_controller.js
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'action']

  connect () {
    this.actionTarget.disabled = true
  }

  agree(){
    if(this.checkboxTarget.checked){
      this.actionTarget.disabled = false 
    } else {
      this.actionTarget.disabled = true
    }
  }
}
