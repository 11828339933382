// src/controllers/clipboard_controller.js
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['type', 'price', 'checkbox']

  connect () {}

  setPrice () {
    if (!this.checkboxTarget.checked) {
      const roomTypeId = this.typeTarget.value
      const urlWithParams = `/room_types/${roomTypeId}`
      fetch(urlWithParams)
        .then(response => response.text())
        .then(data => {
          const roomTypeData = JSON.parse(data)
          const rate = roomTypeData.rate_cents / 100

          this.priceTarget.value = rate
        })
    }
  }
}
