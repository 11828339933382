import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['modal', 'yesBtn']

  connect () {
    if (this.data.get('has-extra') == 'true'){
      console.log('has extra. not showing modal')
    } else {
      $(this.modalTarget).modal()
    }
  }

  close(){
    $(this.modalTarget).modal('hide')
  }

}
