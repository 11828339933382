import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = [
    'button',
    'modal',
    'form',
    'type',
    'number',
    'status',
    'notes'
  ]

  initialize () {
    console.log('conneckt')
    // $(this.modalTarget).modal()
  }

  open (e) {
    e.preventDefault()
    const btn = e.target

    let url = btn.href
    let type = btn.dataset.type
    let number = btn.dataset.number
    let status = btn.dataset.status
    let notes = btn.dataset.notes

    this.formTarget.action = url
    this.statusTarget.value = status
    this.notesTarget.value = notes
    this.typeTarget.innerHTML = type
    this.numberTarget.innerHTML = number

    $(this.modalTarget).modal()
  }
}
