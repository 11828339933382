// src/controllers/clipboard_controller.js
import { Controller } from 'stimulus'
import Compress from "compress.js"
import loadImage from 'blueimp-load-image'

export default class extends Controller {
  static targets = [
    'file',
    'uploadBtn',
    'uploadBtn2',
    'deleteBtn',
    'previewImage',
    'continueBtn',
    'form'
  ]
  connect () {
    if (this.previewImageTarget.src) {
      this.continueBtnTarget.classList.toggle('d-none')
      this.uploadBtnTarget.classList.toggle('d-none')
      this.uploadBtn2Target.classList.toggle('d-none')
      this.deleteBtnTarget.classList.toggle('d-none')
    }
  }

  previewPhoto (e) {
    // since both inputs share the same name, the files are shared between them
    const file = e.currentTarget.files[0]
    this.currentFile = file // store file on controller
    const allowedFiles = ['image/jpeg', 'image/png', 'image/jpg']

    if(allowedFiles.includes('image/jpeg')){
      // Preview photo
      const reader = new FileReader()
      reader.onload = (aImg => {
        return e => {
          aImg.src = e.target.result
  
          this.continueBtnTarget.classList.toggle('d-none')
          this.uploadBtnTarget.classList.toggle('d-none')
          this.uploadBtn2Target.classList.toggle('d-none')
          this.deleteBtnTarget.classList.toggle('d-none')
        }
      })(this.previewImageTarget)
      reader.readAsDataURL(file)
      console.log('original filesize: ', file.size)
    } else {
      alert('Please upload jpeg/jpg or png file')
    }
  }

  compressAndUpload (e) {
    e.preventDefault()
    let form = this.formTarget
    let file = this.currentFile
    if(file){
      loadImage(file, {
        canvas: true,
        maxWidth: 1000,
        downsamplingRatio: 0.7
      }).then(function (data) {
        let base64 = data.image.toDataURL(file.type).split(',')[1]
        let tempFile = Compress.convertBase64ToFile(base64, file.type)
        console.log('compressed: ', tempFile.size, `type: ${file.type}`, data.image.toDataURL().split(',')[0])
        let formData = new FormData(form)
        formData.delete('booking[identification_photo_camera]') // dont wanna upload a large file
        formData.delete('booking[identification_photo_file]') // dont wanna upload a large file
        formData.set('booking[identification_photo]', tempFile) // send a compressed Base64 instead
        fetch(form.action, {
          method: 'POST',
          body: formData
        }).then(response => {
          window.location.href = response.url
        })
      })
    } else {
      window.location.href = this.data.get('next')
    }
  }
}
