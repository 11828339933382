// src/controllers/clipboard_controller.js
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['checkin', 'checkout']

  connect () {
    if (localStorage.getItem('checkin')) {
      this.checkinTarget.value = localStorage.getItem('checkin')
    }
    if (localStorage.getItem('checkout')) {
      this.checkoutTarget.value = localStorage.getItem('checkout')
    }
  }

  changeMin () {
    let checkin = new Date(this.checkinTarget.value)
    let checkout = new Date(this.checkoutTarget.value)

    // checkout min always +1 of checkin
    let minDate = checkin
    minDate.setDate(minDate.getDate() + 1)
    this.checkoutTarget.min = minDate.toISOString().substring(0, 10)

    if (checkout < checkin) {
      this.checkoutTarget.value = ''
    }
  }

  get checkin () {
    new Date(this.checkinTarget.value)
  }

  get checkout () {
    new Date(this.checkoutTarget.value)
  }

  disconnect () {
    localStorage.removeItem('checkin')
    localStorage.removeItem('checkout')
  }
}
